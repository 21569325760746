import React, { useState, useEffect } from 'react';
import { navigate } from "@reach/router"
import moment from 'moment';
import { getUnit, getNormalWoodTable } from '../utils/woodtools'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    },
    woodName: {
        textTransform: 'capitalize',
        fontSize: '1.05rem'
    },
    card: {
        padding: '10px 16px'
    },
    inStock: {
        fontWeight: "800"
    },
    tableRow: {
        cursor: 'pointer'
    },
    cell: {
        padding: '6px 6px 6px 16px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    year: {
        'select' : {
            padding: '6.5px 14px'
        }
    }
}));

function TableBois({ volumeInit, stock, stockAnthony, stockDylan, stockGabin, selectedYear, changeSelectedYear, showInitial, toggleShowInitial }) {
    const classes = useStyles();

    const buildYears = () => {
		let years = []
		let year = Number(moment().format("YYYY"))
		do {
			years.push(
				<option value={year} default key={year}>{year}</option>
			)
			year = year - 1
		} while(year >= 2020)
		return years
	}

	return (
        <div>
            <Grid container spacing={2} style={{'marginBottom': '-2px'}}>
                <Grid item xs>
                    <Typography variant="h6" gutterBottom>Stocks Bois</Typography>
                </Grid>
                <Grid item style={{display:"flex", alignItems: 'center', marginRight: '-16px'}}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch size="small" checked={showInitial} onChange={(e) => toggleShowInitial(e.target.checked)} />}
                            label="Initial"
                        />
                    </FormGroup>
                </Grid>
                <Grid item>
                    <TextField select SelectProps={{native: true}} size="small" value={selectedYear} variant="outlined" onChange={(e) => changeSelectedYear(e.target.value)}>
                        {buildYears()}
                        <option default value="-" key='all'>-</option>
                    </TextField>
                </Grid>
            </Grid>
                
            
            <TableContainer component={Paper} variant="outlined">
                <Table size="small" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <Hidden xsDown>
                                <TableCell>Reference</TableCell>
                            </Hidden>
                            <TableCell>Produit</TableCell>
                            {showInitial && <TableCell align="right">Volume Initial</TableCell>}
                            <TableCell align="right">Anthony</TableCell>
                            <TableCell align="right">Dylan</TableCell>
                            <TableCell align="right">Gabin</TableCell>
                            <TableCell align="right">Stock</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.entries(getNormalWoodTable()).map(([wood, data], index) => (
                        <TableRow key={wood} hover className={classes.tableRow} style={{ backgroundColor: data.color ? data.color : "" }} onClick={() => navigate("/show-stock/"+wood)}>
                        
                            <TableCell className={classes.cell} component="th" scope="row">[{wood}]</TableCell>
                            <Hidden xsDown>
                                <TableCell className={classes.cell} scope="row">{data.name}</TableCell>
                            </Hidden>
                            {showInitial && <TableCell align="right">{volumeInit[wood] ? Math.round((volumeInit[wood] + Number.EPSILON) * 100) / 100+" "+getUnit(wood, true) : "-"}</TableCell>}
                            <TableCell className={classes.cell} align="right">{stockAnthony[wood] ? Math.round((stockAnthony[wood] + Number.EPSILON) * 100) / 100+" "+getUnit(wood, true) : "-"}</TableCell>
                            <TableCell className={classes.cell} align="right">{stockDylan[wood] ? Math.round((stockDylan[wood] + Number.EPSILON) * 100) / 100+" "+getUnit(wood, true) : "-"}</TableCell>
                            <TableCell className={classes.cell} align="right">{stockGabin[wood] ? Math.round((stockGabin[wood] + Number.EPSILON) * 100) / 100+" "+getUnit(wood, true) : "-"}</TableCell>
                            <TableCell className={classes.cell} align="right">{stock[wood] ? Math.round((stock[wood] + Number.EPSILON) * 100) / 100+" "+getUnit(wood, true) : "-"}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
	);
}
	
export default TableBois