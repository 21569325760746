import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import { navigate } from "@reach/router"
import { convertTimestampToHumandeDate } from '../../utils/woodtools'

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    }
}));

function PaymentsSchedule() {
    const classes = useStyles();
    const today = Date.now()/1000

    const [sortedChantiers, setSortedChantiers] = useState([]);

    useEffect(() => {
		var unsubscribe = fire.firestore().collection("chantiers").onSnapshot((snapshot) => {
			let docs = [];
			snapshot.forEach((doc) => {
                const dataDoc = doc.data()
				docs.push({
                    idDoc: doc.id,
                    ...dataDoc
                })
            })
            setSortedChantiers(sortChantiers(docs))
		});
		return () => {
			unsubscribe()
		}
	}, []);

    const sortChantiers = (chantiers) => {
        let arr = chantiers
        arr.sort((a, b) => {
            if(a.paymentDate && b.paymentDate) {
                //payment date first
                return -(a.paymentDate - b.paymentDate)
            } else {
                if(!a.paymentDate && !b.paymentDate) {
                    //with payment date first
                    return -(a.date - b.date)
                } else {
                    if(!!a.paymentDate) {
                        return -1
                    } else {
                        return 1
                    }
                }
            }
        })
        return arr
    }

	return (
		<Container maxWidth="lg" className={classes.root}>
            {/* <Grid container spacing={2}>
                <Grid item xs={12}> */}
                    <Paper className={classes.paper}>
                        <Typography variant="h6" gutterBottom>Paiements client</Typography>
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small" className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Chantier</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <Hidden xsDown>
                                            <TableCell>Méthode de Paiement</TableCell>
                                        </Hidden>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedChantiers.map(data => (
                                        <TableRow 
                                        key={data.idDoc} 
                                        hover 
                                        className={classes.tableRow} 
                                        style={{ backgroundColor: data.color ? data.color : "" }}
                                        style={ (data.paymentDate && today > data.paymentDate && data.paid === false) ? { "backgroundColor": "#ff7b7b" } : {}}>

                                            {/* CHANTIER */}
                                            <Hidden xsDown>
                                                <TableCell component="th" scope="row">
                                                    <b style={{"cursor": "pointer"}} onClick={() => navigate("/show-chantier/"+data.idDoc)}>{data.numeroChantier}</b> - {data.proprietaire}
                                                </TableCell>
                                            </Hidden>
                                            <Hidden smUp>
                                                <TableCell component="th" scope="row">
                                                    <b style={{"cursor": "pointer"}} onClick={() => navigate("/show-chantier/"+data.idDoc)}>{data.numeroChantier}</b><br/>{data.proprietaire}
                                                </TableCell>
                                            </Hidden>
                                            
                                            {/* DATE */}
                                            <TableCell component="th" scope="row">
                                                {(data.paymentDate && today > data.paymentDate && data.paid === false) ? 
                                                    <b>{convertTimestampToHumandeDate(data.paymentDate)}</b> :
                                                    convertTimestampToHumandeDate(data.paymentDate)
                                                }
                                            </TableCell>

                                            {/* STATUS */}
                                            {data.paid && <TableCell component="th" scope="row" style={{color: "#37bb00"}}>Payé</TableCell>}
                                            {data.paid === false && 
                                                <TableCell 
                                                component="th" 
                                                scope="row"
                                                style={!(data.paymentDate && today > data.paymentDate && data.paid === false) ? {color: "#ed0000"} : {}}>
                                                    <b>Non payé</b>
                                                </TableCell>
                                            }
                                            {data.paid === undefined && <TableCell component="th" scope="row">-</TableCell>}
                                            
                                            {/* METHOD */}
                                            <Hidden xsDown>
                                                <TableCell component="th" scope="row">
                                                    { data.paymentMessage &&
                                                        <Tooltip title={data.paymentMessage} arrow>
                                                            <IconButton size="small">
                                                                <InfoIcon fontSize="inherit"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {data.paymentMethod === "transfer" && "Virement"}
                                                    {data.paymentMethod === "check" && "Chèque"}
                                                    {data.paymentMethod === "other" && "-"}
                                                </TableCell>
                                            </Hidden>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                {/* </Grid>
            </Grid> */}
		</Container>
	);
}
	
export default PaymentsSchedule