import React, { useState, useEffect } from 'react';

import {
    Fab, Select, Button,
    TextField,
    Grid,
    Typography,
    Divider,
    InputAdornment,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { Notes } from '@material-ui/icons';

function NewDevis(props) {
    const [openDialogPaiement, setOpenDialogPaiement] = useState(false);
    const [paiementSelect, setPaiementSelect] = useState('Paiement comptant à la signature du contrat.');

    const [openDialogServitude, setOpenDialogServitude] = useState(false);
    const [servitudeSelect, setServitudeSelect] = useState('Stockage des bois en bordure de route.');

    const [openDialogCondition, setOpenDialogCondition] = useState(false);
    const [conditionSelect, setConditionSelect] = useState("Achat en bloc sur pied d'un lot de ");

    useEffect(() => {
        const onHashChange = () => {
            switch (window.location.hash) {
                case "#dialogPaiement":
                    setOpenDialogPaiement(true)
                    setOpenDialogServitude(false)
                    setOpenDialogCondition(false)
                    break;
                case "#dialogServitude":
                    setOpenDialogPaiement(false)
                    setOpenDialogServitude(true)
                    setOpenDialogCondition(false)
                    break;
                case "#dialogCondition":
                    setOpenDialogPaiement(false)
                    setOpenDialogServitude(false)
                    setOpenDialogCondition(true)
                    break;
                default:
                    setOpenDialogPaiement(false)
                    setOpenDialogServitude(false)
                    setOpenDialogCondition(false)
            }
        }
        window.addEventListener("hashchange", onHashChange);
        return () => {
            window.removeEventListener("hashchange", onHashChange);
        }
    }, []);

    useEffect(() => {
        if (window.location.href !== window.location.href.split('#')[0]) {
            window.history.pushState("", document.title, window.location.pathname);
        }
    }, []);

    const setHash = (hash) => {
        window.location.hash = hash;
    }

    const handleClose = () => {
        window.history.back();
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Conditions particulières</Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    fullWidth
                    label="Paiement"
                    multiline
                    value={props.paiement}
                    onChange={(e) => props.getPaiement(e.target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Texte paiement par défaut">
                                    <Fab size="small" color="primary" aria-label="Par default" onClick={() => setHash('#dialogPaiement')}>
                                        <Notes />
                                    </Fab>
                                </Tooltip>
                            </InputAdornment>
                    }} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    fullWidth
                    label="Servitude / Dépôt"
                    multiline
                    value={props.servitude}
                    onChange={(e) => props.getServitude(e.target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Texte servitude / dépôt par défaut">
                                    <Fab size="small" color="primary" aria-label="Par default" onClick={() => setHash('#dialogServitude')}>
                                        <Notes />
                                    </Fab>
                                </Tooltip>
                            </InputAdornment>
                    }} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField fullWidth label="Délais" multiline value={props.delais} onChange={(e) => props.getDelais(e.target.value)} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField fullWidth label="Observations" multiline value={props.observations} onChange={(e) => props.getObservations(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth label="Condition d'achat" multiline value={props.condition} onChange={(e) => props.getCondition(e.target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Condition d'achat par défaut">
                                    <Fab size="small" color="primary" aria-label="Par default" onClick={() => setHash('#dialogCondition')}>
                                        <Notes />
                                    </Fab>
                                </Tooltip>
                            </InputAdornment>
                    }} />
            </Grid>

            {/* Paiement Dialog */}
            <Dialog fullWidth maxWidth='md' open={openDialogPaiement}>
                <DialogTitle>Paiement</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modèles de conditions de paiement
                    </DialogContentText>
                    <DialogContentText>
                        <Select
                            fullWidth
                            variant="outlined"
                            native
                            value={paiementSelect}
                            onChange={(e) => setPaiementSelect(e.target.value)}
                        >
                            <option value="Paiement comptant à la signature du contrat.">Paiement comptant à la signature du contrat.</option>
                            <option value="Paiement comptant au démarrage des travaux.">Paiement comptant au démarrage des travaux.</option>
                            <option value="Paiement comptant à réception des bois bord de route.">Paiement comptant à réception des bois bord de route.</option>
                            <option value="Paiement à 45 jours après réception des bois bord de route le 15 du mois.">Paiement à 45 jours après réception des bois bord de route le 15 du mois.</option>
                            <option value="Paiement à 45 jours après le démarrage des travaux.">Paiement à 45 jours après le démarrage des travaux.</option>
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        annuler
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            handleClose()
                            props.getPaiement(paiementSelect);
                        }}
                    >
                        valider
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Servitude Dialog */}
            <Dialog fullWidth maxWidth='md' open={openDialogServitude}>
                <DialogTitle>Servitude / Dépôt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modèles de conditions de servitude / dépôt
                    </DialogContentText>
                    <DialogContentText>
                        <Select
                            fullWidth
                            variant="outlined"
                            native
                            value={servitudeSelect}
                            onChange={(e) => setServitudeSelect(e.target.value)}
                        >
                            <option value="Stockage des bois en bordure de route.">Stockage des bois en bordure de route.</option>
                            <option value="Stockage des bois en bordure de piste.">Stockage des bois en bordure de piste.</option>
                            <option value="Stockage des bois en bordure de chemin.">Stockage des bois en bordure de chemin.</option>
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        annuler
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            handleClose()
                            props.getServitude(servitudeSelect);
                        }}
                    >
                        valider
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Condition achat Dialog */}
            <Dialog fullWidth maxWidth='md' open={openDialogCondition}>
                <DialogTitle>Condition d'achat</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Modèles de condition d'achat
                    </DialogContentText>
                    <DialogContentText>
                        <Select
                            fullWidth
                            variant="outlined"
                            native
                            value={conditionSelect}
                            onChange={(e) => setConditionSelect(e.target.value)}
                        >
                            <option value="Achat en bloc sur pied d'un lot de ">Achat en bloc sur pied d'un lot de ...</option>
                            <option value="Achat à l'unité de produit sur pieds ">Achat à l'unité de produit sur pieds ...</option>
                            <option value="Achat à l'unité de produit bord de route ">Achat à l'unité de produit bord de route ...</option>
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        annuler
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            handleClose()
                            props.getCondition(conditionSelect);
                        }}
                    >
                        valider
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default NewDevis