import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import moment from 'moment';
import { navigate } from "@reach/router"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TablePapeterie from "../TablePapeterie"
import TableBois from "../TableBois"

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    }
}));

function Stocks() {
    const classes = useStyles();
    
    const [baseStock, setBaseStock] = useState([]);
    
    const [volumeInit, setVolumeInit] = useState(false);
    const [stock, setStock] = useState(false);
    const [stockAnthony, setStockAnthony] = useState(false);
    const [stockDylan, setStockDylan] = useState(false);
    const [stockGabin, setStockGabin] = useState(false);
    
    const [year, setYear] = useState((new Date().getFullYear()).toString());
    const [showInitial, setShowInitial] = useState(false);

    useEffect(() => {
		var unsubscribePiles = fire.firestore().collectionGroup("stock").onSnapshot((snapshot) => {
            let stock = []
            
			snapshot.forEach((doc) => {
				stock.push(doc.data())
            })
            setBaseStock(stock)
        });

		return () => unsubscribePiles()
    }, []);

    useEffect(() => {
        let startAt;
		let endAt;
		if(year === "-") {
			startAt = parseInt((new Date("1999").getTime() / 1000).toFixed(0))
			endAt = parseInt((new Date("2200").getTime() / 1000).toFixed(0))
		} else {
			let startYear = moment(year);
			let endYear = moment(year).add(1, 'years');
			startAt = parseInt((new Date(startYear).getTime() / 1000).toFixed(0))
			endAt = parseInt((new Date(endYear).getTime() / 1000).toFixed(0))
		}
        setTableStocks(baseStock.filter((v) => (v.date > startAt && v.date < endAt)))
    }, [baseStock, year]);

    const setTableStocks = (stock) => {
        let volumeInit = {}
        let volumeRestant = {}
        let volumeAnthony = {}
        let volumeDylan = {}
        let volumeGabin = {}
        stock && stock.map((stock) => {
            if(volumeInit[stock.type] === undefined){
                volumeInit[stock.type] = 0
            }
            volumeInit[stock.type] += Number(stock.volumeInitial)

            switch(stock.numeroChantier.charAt(0)) {
                case "A":
                    if(volumeAnthony[stock.type] === undefined){
                        volumeAnthony[stock.type] = 0
                    }
                    volumeAnthony[stock.type] += Number(stock.stock)
                    break;
                case "D":
                    if(volumeDylan[stock.type] === undefined){
                        volumeDylan[stock.type] = 0
                    }
                    volumeDylan[stock.type] += Number(stock.stock)
                    break;
                case "G":
                    if(volumeGabin[stock.type] === undefined){
                        volumeGabin[stock.type] = 0
                    }
                    volumeGabin[stock.type] += Number(stock.stock)
                    break;
            }
            
            if(volumeRestant[stock.type] === undefined){
                volumeRestant[stock.type] = 0
            }
            volumeRestant[stock.type] += Number(stock.stock)
        })
        setVolumeInit({ ...volumeInit })
        setStock({ ...volumeRestant })
        setStockAnthony({ ...volumeAnthony });
        setStockDylan({ ...volumeDylan });
        setStockGabin({ ...volumeGabin });
    }

	return (
		<Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableBois
                                volumeInit={volumeInit}
                                stock={stock} 
                                stockAnthony={stockAnthony}
                                stockDylan={stockDylan} 
                                stockGabin={stockGabin} 
                                selectedYear={year} 
                                changeSelectedYear={(year) => setYear(year)} 
                                showInitial={showInitial}
                                toggleShowInitial={(show) => setShowInitial(show)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TablePapeterie volumeInit={volumeInit} stock={stock} stockAnthony={stockAnthony} stockDylan={stockDylan} stockGabin={stockGabin} showInitial={showInitial}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item>
                    <Button onClick={() => navigate('/stocks-price')} color="primary">
                        Consulter le tableau des prix
                    </Button>
                </Grid>
            </Grid>
		</Container>
	);
}
	
export default Stocks