import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import { navigate } from "@reach/router"
import { sortStackByCategory,
    getRefName, 
    getUnit, 
    getBackgroundColor, 
    getCahierDesCharges, 
    convertTimestampToHumandeDate,
    convertDateToTimestamp } from "../../utils/woodtools"
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import ExploreIcon from '@material-ui/icons/Explore';
import RoomIcon from '@material-ui/icons/Room';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ButtonBase from '@material-ui/core/ButtonBase';
//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import EditIcon from '@material-ui/icons/Edit';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import ReceptionChantierPDF from '../../pdf/ReceptionChantierPDF'
import ContratAchatPDF from '../../pdf/ContratAchatPDF'
import InfosChantier from '../InfosChantier'
import StockManager from '../StockManager'


const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    },
	tableHead: {
		backgroundColor: "#f1f1f1"
    },
    iconInit: {
        color: "#00E676"
    },
    iconDebit: {
        color: "#FF1744"
    },
    iconFinal: {
        color: "#2979FF"
    },
    rowIcon: {
        display: "flex"
    },
    stockEmpty: {
        color: "#00b0ff"
    },
    inStock: {
        color: "#f57c00"
    },
    collapseContent: {
        paddingTop: "0px",
        paddingBottom: "15px !important"
    },
    cardContent: {
        padding: "10px 16px"
    },
    toRightButton: {
        display: "flex",
        marginLeft: "auto"
    },
    tableTransaction: {
        borderCollapse: "inherit"
    },
    subRowPile: {
        borderBottomColor: "#f1f1f1",
    },
    subResultRow: {
        backgroundColor: "#fafafa"
    }
}));

function ShowChantier(params) {
    const classes = useStyles();
    const [chantier, setChantier] = useState(false)
    const [stock, setStock] = useState(false)
    const [unsortedWoods, setUnsortedWoods] = useState(false)
    const [sortedWoods, setSortedWoods] = useState(false)

    const [dialogSortie, setDialogSortie] = useState(false)
    const [currentType, setCurrentType] = useState(false)
    const [currentNumFacture, setCurrentNumFacture] = useState("")
    const [currentDate, setCurrentDate] = useState("")
    const [currentQuantite, setCurrentQuantite] = useState("")

    useEffect(()=> {

        let chantierUnsubscribe = fire.firestore().collection("chantiers").doc(params.idChantier).onSnapshot((doc) => {
            if (doc.exists) {
                console.log("Doc id from cache : " + doc.metadata.fromCache);
                console.log("Document data:", doc.data());
                setChantier(doc.data());
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                navigate('/liste-chantiers/now')
            }
        });
        
        //getPiles
        let woodsList;
        let pilesUnsubscribe = fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").orderBy("type").onSnapshot((snapshot) => {
            woodsList = {};
            snapshot.forEach((doc) => {
                woodsList[doc.id] = doc.data()
            })
            setUnsortedWoods({...woodsList})
            woodsList = sortStackByCategory(woodsList)
            setSortedWoods({...woodsList})
            // searchTransactions(woodsList)
        });

        let stockUnsubscribe = fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").onSnapshot((snapshot) => {
            let stocksType = {};
            snapshot.forEach((doc) => {
                stocksType[doc.data().type] = doc.data()
            })
            setStock(stocksType)
        });

        return () => {
            // window.removeEventListener("hashchange", onHashChange);
            chantierUnsubscribe()
            pilesUnsubscribe()
            stockUnsubscribe()
        }
    }, []);

    const resetCurrent = () => {
        setCurrentNumFacture("")
        setCurrentDate("")
        setCurrentQuantite("")
        setCurrentType(false)
    }

    const addSortie = () => {
        console.log("new sortie pile ", currentType)
        // let newTransactions = "jul"
        let newFacture = {...stock[currentType].factures}
        let timestamp = convertDateToTimestamp(currentDate)
        console.log(newFacture)
        newFacture[currentNumFacture || "-"] = {
            numeroFacture: currentNumFacture,
            date: timestamp,
            before: Number(stock[currentType].stock),
            after: Number(currentQuantite)
        }
        console.log(newFacture)
        fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").doc(currentType).update({
            stock: Number(currentQuantite),
            factures: newFacture
        })
        .then(function() {
            console.log("liste factures successfully updated!");
            resetCurrent()
            verifChantierVide()
        })
        .catch(function(error) {
            // The document probably doesn't exist.
            console.error("Error updating liste factures: ", error);
        });
    }

    const verifChantierVide = () => {
        fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").get()
        .then((docs) => {
            let stock = 0
            docs.forEach((doc) => {
                let data = doc.data()
                stock += Number(data.stock)
            })
            if(stock === 0) {
                console.log("Sold out chantier")
                fire.firestore().collection("chantiers").doc(params.idChantier).update({
                    soldOut: true
                }).then(() => {
                    fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").get()
                    .then((docs) => {
                        docs.forEach((doc) => {
                            fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").doc(doc.id).update({
                                soldOut: true
                            })
                        })
                    })
                    fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").get()
                    .then((docs) => {
                        docs.forEach((doc) => {
                            fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").doc(doc.id).update({
                                soldOut: true
                            })
                        })
                    })
                })
            }
        })
        
    }

	return (
		<Container maxWidth="lg" className={classes.root}>

            {/* INFOS */}
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <InfosChantier chantier={chantier} piles={unsortedWoods} idDocChantier={params.idChantier}/>
                </Grid>
                
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Stock initial</Typography>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <Hidden xsDown>
                                                    <TableCell component="th" scope="row">Code</TableCell>
                                                </Hidden>
                                                <TableCell component="th" scope="row">Produit</TableCell>
                                                <Hidden xsDown>
                                                    <TableCell align="left">Prix H.T</TableCell>
                                                </Hidden>
                                                <TableCell align="right">N° Pile</TableCell>
                                                <Hidden xsDown>
                                                    <TableCell align="right">Cotes</TableCell>
                                                </Hidden>
                                                <TableCell align="right">Quantité</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {Object.entries(sortedWoods).map(([reference, bois], index) => {
                                            let globalVolume = 0
                                            let unit = ""
                                            let piles = Object.entries(bois).map(([refPile, pile], i) => {
                                                globalVolume += pile.volume
                                                unit = getUnit(pile.type)
                                                return (
                                                <TableRow key={i} style={{ backgroundColor: getBackgroundColor(reference) }}>
                                                    <Hidden xsDown>
                                                        <TableCell component="th" scope="row" className={classes.subRowPile}>{i==0 ? reference :''}</TableCell>
                                                    </Hidden>
                                                    <TableCell component="th" scope="row" className={classes.subRowPile}>
                                                        {i==0 ? <span>{getRefName(reference)}<br/><i>{pile.cahierDesCharges}</i></span>:''}
                                                        {i==0 && pile.commentaire ? <br/> : null}
                                                        {pile.commentaire ? <Typography variant="caption" style={{color: "#0876ce"}}>Commentaire pile : {pile.commentaire}</Typography> : null}
                                                    </TableCell>
                                                    <Hidden xsDown>
                                                        {pile.isPrixGlobal ?
                                                            <TableCell align="left" className={classes.subRowPile}>{i==0 && pile.prixUnitaireHT ? pile.prixUnitaireHT+" €" :'-'}</TableCell> :
                                                            <TableCell align="left" className={classes.subRowPile}>{i==0 && pile.prixUnitaireHT ? pile.prixUnitaireHT+" € / " + getUnit(pile.type, true) :'-'}</TableCell>}
                                                        {/* <TableCell align="left" className={classes.subRowPile}>{i==0 ? pile.prixUnitaireHT+" €" :''}</TableCell> */}
                                                    </Hidden>
                                                    <TableCell align="right" className={classes.subRowPile}>{pile.numeroPile || "-"}</TableCell>
                                                    <Hidden xsDown>
                                                        <TableCell align="right" className={classes.subRowPile}>{pile.longeur !== undefined ? (pile.longeur+"x"+pile.largeur+"x"+pile.hauteur+"m") : "-"}</TableCell>
                                                    </Hidden>

                                                    <Hidden xsDown>
                                                        <TableCell align="right" className={classes.subRowPile}>{pile.volume} {getUnit(reference)}</TableCell>
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <TableCell align="right" className={classes.subRowPile}>{pile.volume} {getUnit(reference, true)}</TableCell>
                                                    </Hidden>
                                                </TableRow>)
                                            })
                                            return [ piles ,
                                            <TableRow key={index} className={classes.subResultRow}>
                                                <Hidden xsDown>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell><TableCell></TableCell>
                                                </Hidden>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right">Total</TableCell>
                                                <Hidden xsDown>
                                                    <TableCell align="right">{Math.round((globalVolume + Number.EPSILON) * 100) / 100} {getUnit(reference)}</TableCell>
                                                </Hidden>
                                                <Hidden smUp>
                                                    <TableCell align="right">{Math.round((globalVolume + Number.EPSILON) * 100) / 100} {getUnit(reference, true)}</TableCell>
                                                </Hidden>
                                            </TableRow>]
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid container justify="flex-end" item xs={12} spacing={1}>
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={() => navigate('/edit-chantier-piles/'+params.idChantier)}>Modifier</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                

                {/* Suivi */}
                {/* <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography variant="h6" gutterBottom>Stock</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small" className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className={classes.tableHead}>
                                                <TableCell component="th" scope="row">Produit</TableCell>
                                                <Hidden xsDown>
                                                    <TableCell align="right">Volume Initial</TableCell>
                                                </Hidden>
                                                <TableCell align="right">Stock</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {Object.entries(stock).map(([refWood, dataWood], index) => (
                                            <TableRow key={refWood} className={classes.tableRow}
                                            style={{ backgroundColor: getBackgroundColor(refWood) }}
                                            >
                                                <TableCell component="th" scope="row">[{refWood}]</TableCell>
                                                <Hidden smUp>
                                                    <TableCell align="right">{(dataWood.stock || "0") +" "+getUnit(refWood)}</TableCell>
                                                </Hidden>
                                                <Hidden xsDown>
                                                    <TableCell align="right">{(dataWood.volumeInitial || "0") +" "+getUnit(refWood, true)}</TableCell>
                                                    <TableCell align="right">{(dataWood.stock) +" "+getUnit(refWood, true)}</TableCell>
                                                </Hidden>
                                                <TableCell align="right">
                                                        <IconButton
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogSortie(true)
                                                            setCurrentType(refWood)
                                                            setCurrentQuantite(0)
                                                            setCurrentDate(moment().format("YYYY-MM-DD"))
                                                        }}>
                                                            <EditIcon fontSize="inherit"/>
                                                        </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Liste des factures</Typography>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Facture</TableCell>
                                                <TableCell align="right">Volume</TableCell>
                                                <TableCell align="right">Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(stock).map(([refType, dataType]) => {
                                                console.log("stock :", refType, dataType)
                                                let factures = dataType.factures
                                                return Object.entries(factures).map(([refFacture, dataFacture]) => (
                                                    <TableRow key={refFacture}>
                                                        <TableCell component="th" scope="row">{dataFacture.numeroFacture} - [{refType}]</TableCell>
                                                        <TableCell align="right">{dataFacture.before}<ArrowRightAltIcon size="small" style={{margin: '-7px 0px'}}/>{dataFacture.after}</TableCell>
                                                        <TableCell align="right">{convertTimestampToHumandeDate(dataFacture.date)}</TableCell>
                                                    </TableRow>
                                                ))
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> */}

                <Grid item xs={12}>
                    <StockManager stock={stock} idDocChantier={params.idChantier} soldOut={chantier.soldOut} />
                </Grid>
            </Grid>




            {/* Dialog Add Transaction*/}
            <Dialog 
                open={dialogSortie} 
                onClose={() => {
                    setDialogSortie(false)
                    resetCurrent()
                    }
                }
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{currentType || "-"}</DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12}>
                                <TextField fullWidth type="text" label="Numero Facture" value={currentNumFacture} onChange={(e) => setCurrentNumFacture(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                fullWidth 
                                type="number" 
                                label="Quantité restante"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{currentType && getUnit(currentType)}</InputAdornment>,
                                    inputProps: { min: 0, step: ".1" , max: currentType && stock[currentType].stock },
                                }}
                                value={currentQuantite}
                                onChange={(e) => setCurrentQuantite(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    label="Date"
                                    type="date"
                                    fullWidth
                                    // defaultValue="2017-05-24"
                                    // className={classes.textField}
                                    value={currentDate}
                                    onChange={(e) => setCurrentDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button 
                    onClick={() => {
                        setDialogSortie(false)
                        resetCurrent()
                        }
                    }
                    color="primary">
                        Annuler
                    </Button>
                    <Button 
                    onClick={() => {
                        setDialogSortie(false)
                        addSortie()
                    }} 
                    color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>

		</Container>
	);
}
	
export default ShowChantier