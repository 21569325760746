import React, { useState, useEffect } from 'react';
import fire from '../utils/fire';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    }
}));

function BackupPage(params) {
    const classes = useStyles();

    const [chantiers, setChantiers] = useState({})
    const [stocks, setStocks] = useState({})
    const [piles, setPiles] = useState({})
    const [devis, setDevis] = useState({})
    
    const saveChantiers = () => {
        let chantiers = {}
        fire.firestore().collection("chantiers").get().then((docs) => {
            docs.forEach((doc) => {
                chantiers[doc.id] = doc.data()
            })
            setChantiers(chantiers)
        })
    }
    const printChantiers = () => {console.log(chantiers); console.log(JSON.stringify(chantiers))}

    const saveStock = () => {
        let stocks = {}
        fire.firestore().collectionGroup("stock").get().then((docsStock) => {
            docsStock.forEach((docStock) => {
                if(stocks[docStock.ref.parent.parent.id] === undefined)
                    stocks[docStock.ref.parent.parent.id] = {}
                let data = docStock.data()
                stocks[docStock.ref.parent.parent.id][data.type] = docStock.data()
            })
            setStocks(stocks)
        })
    }
    const printStock = () => {console.log(stocks); console.log(JSON.stringify(stocks))}

    const savePiles = () => {
        let piles = {}
        fire.firestore().collectionGroup("piles").get().then((docsPiles) => {
            docsPiles.forEach((docPile) => {
                if(piles[docPile.ref.parent.parent.id] === undefined)
                    piles[docPile.ref.parent.parent.id] = {}
                piles[docPile.ref.parent.parent.id][docPile.id] = docPile.data()
            })
            setPiles(piles)
        })
    }
    const printPiles = () => {console.log(piles); console.log(JSON.stringify(piles))}

    const saveDevis = () => {
        let devis = {}
        fire.firestore().collection("devis").get().then((docs) => {
            docs.forEach((doc) => {
                devis[doc.id] = doc.data()
            })
            setDevis(devis)
        })
    }
    const printDevis = () => {console.log(devis);console.log(JSON.stringify(devis))}

    //putting chantiers
    // let chantiers = {}
    // Object.entries(chantiers).map(([key, val], i) => {
    //     fire.firestore().collection("chantiers").doc(key).set(val)
    //     .then(() => console.log("hit"))
    //     .catch((e) => console.log("miss", e))
    // })

    //puttin devis
    // let devis = {}
    // Object.entries(devis).map(([key, val], i) => {
    //     fire.firestore().collection("devis").doc(key).set(val)
    //     .then(() => console.log("hit"))
    //     .catch((e) => console.log("miss", e))
    // })

    // putting stock
    // let obj = {}
    // Object.entries(obj).map(([key, val], i) => {
    //     Object.entries(val).map(([type, data], j) => {
    //         fire.firestore().collection("chantiers").doc(key).collection("stock").doc(type)
    //         .set(data)
    //         .then(() => console.log("hit"))
    //         .catch((e) => console.log("miss", e))
    //     })
    // })

    // putting piles
    // let obj = {}
    // Object.entries(obj).map(([key, val], i) => {
    //     Object.entries(val).map(([idDoc, data], j) => {
    //         fire.firestore().collection("chantiers").doc(key).collection("piles").doc(idDoc)
    //         .set(data)
    //         .then(() => console.log("hit"))
    //         .catch((e) => console.log("miss", e))
    //     })
    // })

    const [idChantier, setIdChantier] = useState("")

	return (
		<Container maxWidth="lg" className={classes.root}>
            
            <TextField fullWidth label="Id Doc Chantier" value={idChantier} onChange={(e) => setIdChantier(e.target.value)}/>
            <Button onClick={() => console.log({idChantier: idChantier, chantier: "..."})}>Export Chantier</Button>

            <Divider />

            <Box>
                <Button onClick={() => saveChantiers()}>SAVE ALL FIREBASE</Button>
                <Button onClick={() => printChantiers()}>console.log Chantier</Button>
            </Box>
            <Box>
                <Button onClick={() => saveStock()}>SAVE ALL FIREBASE</Button>
                <Button onClick={() => printStock()}>console.log Stock</Button>
            </Box>
            <Box>
                <Button onClick={() => savePiles()}>SAVE ALL FIREBASE</Button>
                <Button onClick={() => printPiles()}>console.log Piles</Button>
            </Box>
            <Box>
                <Button onClick={() => saveDevis()}>SAVE ALL FIREBASE</Button>
                <Button onClick={() => printDevis()}>console.log Devis</Button>
            </Box>

		</Container>
	);
}
	
export default BackupPage
