import React, { useState, useEffect } from "react"
import { navigate } from '@reach/router';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser, isLoggedIn, notifyLoginChangeListener } from "../utils/auth"
import fire from "firebase"

const Login = () => {
	const [configUi, setConfigUi] = useState(null);

	useEffect(()=> {
		if (isLoggedIn()) {
			navigate(`/`)
		}
		setConfigUi(getUiConfig())
	}, [])
	
	function getUiConfig() {
		return {
			signInFlow: 'popup',
			signInOptions: [
				fire.auth.GoogleAuthProvider.PROVIDER_ID,
				fire.auth.EmailAuthProvider.PROVIDER_ID
			],
			signInSuccessUrl: '/',
			callbacks: {
				signInSuccessWithAuthResult: (result) => {
					setUser(result.user);
					notifyLoginChangeListener()
					navigate('/');
				}
			}
		};
	}
	
	if (!configUi) {
		return <p>Chargement...</p>
	}
	
	return (
		<div>
			{/* <p>Please sign-in to access to the private route:</p> */}
			<StyledFirebaseAuth uiConfig={configUi} firebaseAuth={fire.auth()}/>
			{/* <div id="logui"></div> */}
		</div>
	);
		
}

export default Login
	