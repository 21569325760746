import React , { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import moment from "moment"
import { makeStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import MUIDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TodayIcon from '@material-ui/icons/Today';
import PaymentIcon from '@material-ui/icons/Payment';

import { isLoggedIn } from "./utils/auth";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	version: {
		textAlign: "center",
		padding: "20px 10px",
		textDecoration: "underline",
		marginTop: "auto",
		[theme.breakpoints.down('md')]: {
			padding: "10px",
		},
	},
	nested: {
		paddingLeft: theme.spacing(5),
		// paddingTop: '5px',
		// paddingBottom: '5px'
	},
	lightDivider: {
		backgroundColor: "#0000000f",
	},
	yearList: {
		padding: 0
	}
}));

const Drawer = ({ openDrawer , setOpenDrawer }) => {
	const classes = useStyles();
	const [update, setUpdate] = useState(false)

	useEffect(() => {
		const myFunc = () => {
			console.log("Drawer notified")
			setUpdate(!update)
		}
		document.addEventListener('loginStatusChange', myFunc, false)
		return () => {
			document.removeEventListener("loginStatusChange", myFunc);
		}
	})
	console.log("render Drawer")

	const buildYears = () => {
		let years = []
		let year = Number(moment().format("YYYY"))
		do {
			let currentYear = year;
			years.push(
				<ListItem button key={Number(year)} className={classes.nested} onClick={() => {navigate('/liste-chantiers/'+currentYear); setOpenDrawer(false)}}>
					<ListItemIcon>
						<TodayIcon />
					</ListItemIcon>
					<ListItemText primary={Number(year)} />
				</ListItem>
			)
			year = year - 1
		} while(year >= 2020)
		return years
	}

	// MENU BAR
    const makeDrawer = () => {
		if(isLoggedIn()) {
			//AUTH
			return (
				<div>
					<div className={classes.toolbar} />
					<Divider />
					<List>
						<ListItem button key="Stocks" onClick={() => {navigate('/stocks'); setOpenDrawer(false)}}>
							<ListItemIcon><InsertChartIcon /></ListItemIcon>
							<ListItemText primary="Stocks"/>
						</ListItem>

						<Divider className={classes.lightDivider} />

						<ListItem button key="Chantiers" onClick={() => {navigate('/liste-chantiers/now'); setOpenDrawer(false)}}>
							<ListItemIcon>
								<AssignmentIcon />
							</ListItemIcon>
							<ListItemText primary="Chantiers"/>
							{/* <ExpandMore /> */}
						</ListItem>
						<List className={classes.yearList}>
							{buildYears()}
						</List>

						<Divider className={classes.lightDivider} />

						<ListItem button key="Devis" onClick={() => {navigate('/liste-devis'); setOpenDrawer(false)}}>
							<ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
							<ListItemText primary="Devis"/>
						</ListItem>

						<Divider className={classes.lightDivider} />

						<ListItem button key="Payments" onClick={() => {navigate('/payments'); setOpenDrawer(false)}}>
							<ListItemIcon><PaymentIcon /></ListItemIcon>
							<ListItemText primary="Paiements"/>
						</ListItem>

					</List>

					{/* <Divider />
					<ListItem button key="Devis" onClick={() => document.dispatchEvent(event)}>
						<ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
						<ListItemText primary="Devis"/>
					</ListItem> */}
					{/* <List>
						{['Exemple'].map((text, index) => (
						<ListItem button key={text} onClick={() => {navigate('/'); setMobileOpen(false)}}>
							<ListItemIcon>{index % 2 === 0 ? <DescriptionIcon /> : <InsertChartIcon />}</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
						))}
					</List> */}
					

				</div>
			)
		} else {
			//PAS AUTH
			return (
				<div>
					<div className={classes.toolbar} />
					<Divider />
					<List>
						<ListItem button key="Login" onClick={() => navigate('/login')}>
							<ListItemIcon><ExitToAppIcon /></ListItemIcon>
							<ListItemText primary="Login" />
						</ListItem>
					</List>
				</div>
			)
		}
	}

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
		<nav className={classes.drawer}>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Hidden mdUp>
				<SwipeableDrawer
				disableBackdropTransition={!iOS}
				classes={{
					paper: classes.drawerPaper
				}}
				anchor="left"
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
				disableDiscovery={iOS}
				>
					{makeDrawer()}
					{/* <Typography className={classes.version} variant="caption">v0.10.2</Typography> */}
				</SwipeableDrawer>
			</Hidden>
			<Hidden smDown>
				<MUIDrawer
				classes={{
					paper: classes.drawerPaper
				}}
				variant="permanent"
				open>
					{makeDrawer()}
					{/* <Typography className={classes.version} variant="caption">v0.10.2</Typography> */}
				</MUIDrawer>
			</Hidden>
			
		</nav>
    )
}
export default Drawer
