import React, { useState, useEffect } from 'react';
import fire from '../utils/fire';
import { getUnit, 
    getBackgroundColor,
    convertTimestampToHumandeDate,
    convertDateToTimestamp } from "../utils/woodtools"
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import EditIcon from '@material-ui/icons/Edit';

import { dispatchNotification } from "../utils/notification"


const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    },
	tableHead: {
		backgroundColor: "#f1f1f1"
    },
    iconInit: {
        color: "#00E676"
    },
    iconDebit: {
        color: "#FF1744"
    },
    iconFinal: {
        color: "#2979FF"
    },
    rowIcon: {
        display: "flex"
    },
    stockEmpty: {
        color: "#00b0ff"
    },
    inStock: {
        color: "#f57c00"
    },
    collapseContent: {
        paddingTop: "0px",
        paddingBottom: "15px !important"
    },
    cardContent: {
        padding: "10px 16px"
    },
    toRightButton: {
        display: "flex",
        marginLeft: "auto"
    },
    tableTransaction: {
        borderCollapse: "inherit"
    },
    subRowPile: {
        borderBottomColor: "#f1f1f1",
    },
    subResultRow: {
        backgroundColor: "#fafafa"
    }
}));

function StockManager({ stock, idDocChantier, soldOut }) {
    // const [stockii, setStock] = useState({
    //     "CHAPE":{
    //        "prixUnitaireHT":"2.5",
    //        "type":"CHAPE",
    //        "cahierDesCharges":"longueur 2-10m, Ø > 5 cm",
    //        "proprietaire":"jul51",
    //        "date":1605484800,
    //        "factures":{
              
    //        },
    //        "volumeInitial":20,
    //        "stock":20,
    //        "isPrixGlobal":false,
    //        "numeroChantier":"A-20-100"
    //     },
    //     "CHECH":{
    //         "cahierDesCharges":"Bois sain, non gelé, droit, Nœud < 7cm, lg + 4m, Ø > 28cm",
    //         "proprietaire":"jul51",
    //         "date":1605484800,
    //         "type":"CHECH",
    //         "stock":50,
    //         "isPrixGlobal":true,
    //         "numeroChantier":"A-20-100",
    //         "factures":{
    //             "1608076800":{
    //                 "before":182.7,
    //                 "detail":"5894",
    //                 "after":100
    //             },
    //             "1608076801":{
    //                 "before":100,
    //                 "after":50
    //             }
    //        },
    //        "prixUnitaireHT":"50",
    //        "volumeInitial":182.7
    //     },
    //     "PEPAP":{
    //        "prixUnitaireHT":"5",
    //        "stock":3,
    //        "isPrixGlobal":true,
    //        "proprietaire":"jul51",
    //        "numeroChantier":"A-20-100",
    //        "volumeInitial":3,
    //        "type":"PEPAP",
    //        "cahierDesCharges":"Papeterie, lg : 2m, Ø > 7cm",
    //        "factures":{
    //            "1608336000":{
    //                "before":182.7,
    //                "after":100
    //            },
    //            "1608336001":{
    //                "before":100,
    //                "detail":"5894",
    //                "after":50
    //            }
    //       },
    //        "date":1605484800
    //     }
    // })

    const classes = useStyles();
    // const [chantier, setChantier] = useState(false)
    // const [stock, setStock] = useState(false)
    // const [unsortedWoods, setUnsortedWoods] = useState(false)
    // const [sortedWoods, setSortedWoods] = useState(false)

    const [dialogSortie, setDialogSortie] = useState(window.location.hash === "#dialogSortie")
    const [currentType, setCurrentType] = useState(false)
    const [currentNumFacture, setCurrentNumFacture] = useState("")
    const [currentDate, setCurrentDate] = useState("")
    const [currentQuantite, setCurrentQuantite] = useState("")

    useEffect(()=> {
        const onHashChange = () => {
            switch(window.location.hash) {
                case "#dialogSortie" :
                    setDialogSortie(true)
                    // setOpenDialogDeleteDevis(false)
                    // setOpenDialogDelete(false)
                    break;
                default :
                    setDialogSortie(false)
                    // setOpenDialogDeleteDevis(false)
                    // setOpenDialogDelete(false)
            }
        }
        window.addEventListener("hashchange", onHashChange);

        return () => {
            window.removeEventListener("hashchange", onHashChange);
        }
    }, []);

    const resetCurrent = () => {
        setCurrentNumFacture("")
        setCurrentDate("")
        setCurrentQuantite("")
        setCurrentType(false)
    }

    const addSortie = () => {
        console.log("new sortie pile ", currentType)
        let newFacture = {...stock[currentType].factures}
        let timestamp = currentDate

        //verif timestamp exist deja
        let factures = facturesOrderByDate(newFacture)
        factures.map((dataFacture) => {
            console.log(dataFacture.date, timestamp)
            if(Number(dataFacture.date) === Number(timestamp)) {
                timestamp += 1
            }
        })

        newFacture[timestamp] = {
            before: Number(stock[currentType].stock),
            after: Number(currentQuantite)
        }
        if(currentNumFacture !== "") {
            newFacture[timestamp].detail = currentNumFacture
        }
        console.log(newFacture)

        fire.firestore().collection("chantiers").doc(idDocChantier).collection("stock").doc(currentType).update({
            stock: Number(currentQuantite),
            factures: newFacture
        })
        .then(() => {
            console.log("liste factures successfully updated!");
                let newStock = {...stock}
                newStock[currentType].factures = newFacture
                newStock[currentType].stock = Number(currentQuantite)
                verifChantierVide(newStock) ? dispatchNotification("Ce chantier est vide et ne contient plus de bois !") : dispatchNotification("Le stock a été mis à jour !")
                resetCurrent()
        })
        .catch((error) => {
            console.error("Error updating liste factures: ", error);
        });
    }

    const verifChantierVide = (stock) => {
        console.log(stock)
        let stockTotal = 0
        Object.entries(stock).map(([refWood, dataStock]) => {
            stockTotal += Number(dataStock.stock)
        })
        console.log(stockTotal)
        if(stockTotal === 0) {
            //set SOLDOUT
            fire.firestore().collection("chantiers").doc(idDocChantier).update({
                soldOut: true
            })
            .then(() => {
                console.log("SOLD OUT !")
                dispatchNotification("Ce chantier est vide et ne contient plus de bois !")
                return true
            })
            .catch((error) => {
                console.error("Error updating soldOut: ", error);
            });
        } else if (soldOut && stockTotal !== 0) {
            //unset SOLDOUT
            fire.firestore().collection("chantiers").doc(idDocChantier).update({
                soldOut: false
            })
            .then(() => {
                console.log("NOT SOLD OUT ANYMORE !")
                return false
            })
            .catch((error) => {
                console.error("Error updating soldOut: ", error);
            });
        }
        // fire.firestore().collection("chantiers").doc(idDocChantier).collection("stock").get()
        // .then((docs) => {
    //         let stock = 0
    //         docs.forEach((doc) => {
    //             let data = doc.data()
    //             stock += Number(data.stock)
    //         })
    //         if(stock === 0) {
    //             console.log("Sold out chantier")
    //             fire.firestore().collection("chantiers").doc(params.idChantier).update({
    //                 soldOut: true
    //             }).then(() => {
    //                 fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").get()
    //                 .then((docs) => {
    //                     docs.forEach((doc) => {
    //                         fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").doc(doc.id).update({
    //                             soldOut: true
    //                         })
    //                     })
    //                 })
    //                 fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").get()
    //                 .then((docs) => {
    //                     docs.forEach((doc) => {
    //                         fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").doc(doc.id).update({
    //                             soldOut: true
    //                         })
    //                     })
    //                 })
    //             })
    //         }
        // })
    }

    const nowTimestamp = () => {
        let tmp = moment().unix()
        console.log(tmp)
        console.log(moment.unix(tmp).format("DD/MM/YYYY"))
        return moment.unix(tmp).format("YYYY-MM-DD H:mm:ss")
    }

    const facturesOrderByDate = (factures) => {
        const compare = (a, b) => {
            const dateA = a.date;
            const dateB = b.date;
        
            //compare stock
            if (dateA > dateB) {return 1} 
            else if (dateA < dateB) {return -1} 
            else {return 0}
        }

        let sortedFactures = []
        Object.entries(factures).map(([idFacture, dataFacture]) => {
            sortedFactures.push({
                date: idFacture,
                ...dataFacture
            })
        })
        return sortedFactures.sort(compare)
    }

	return (
        <div>
            <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>Gestion du Stock</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small" className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableHead}>
                                        <TableCell component="th" scope="row">Produit</TableCell>
                                        <Hidden xsDown>
                                            <TableCell align="right">Volume Initial</TableCell>
                                        </Hidden>
                                        <TableCell align="right">Stock</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {Object.entries(stock).map(([refWood, dataWood], index) => (
                                    <TableRow key={refWood} className={classes.tableRow}
                                    style={{ backgroundColor: getBackgroundColor(refWood) }}>
                                        <TableCell component="th" scope="row">[{refWood}]</TableCell>
                                        <Hidden smUp>
                                            {/* <TableCell align="right">{(dataWood.volumeInitial || "-") +" "+getUnit(refWood)}</TableCell> */}
                                            <TableCell align="right">{(dataWood.stock || "0") +" "+getUnit(refWood)}</TableCell>
                                        </Hidden>
                                        <Hidden xsDown>
                                            <TableCell align="right">{(dataWood.volumeInitial || "0") +" "+getUnit(refWood, true)}</TableCell>
                                            <TableCell align="right">{(dataWood.stock) +" "+getUnit(refWood, true)}</TableCell>
                                        </Hidden>
                                        <TableCell align="right">
                                            {/* {dataWood.stock === 0 ? null : */}
                                                <IconButton
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => {
                                                    // setDialogSortie(true)
                                                    setCurrentNumFacture("")
                                                    setCurrentType(refWood)
                                                    setCurrentQuantite(0)
                                                    setCurrentDate(moment().unix())
                                                    window.location.hash = "#dialogSortie";
                                                }}>
                                                    <EditIcon fontSize="inherit"/>
                                                </IconButton>
                                            {/* } */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Divider />
                        {/* <button onClick={() => console.log(nowTimestamp())}>time</button> */}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="overline" gutterBottom>Historique des mouvements de stock</Typography>
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small" aria-label="a dense table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Date</TableCell>
                                        <TableCell component="th" scope="row">Bois</TableCell>
                                        <TableCell align="right">Volume</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(stock).map(([refType, dataType]) => {
                                        let factures = facturesOrderByDate(dataType.factures)
                                        return factures && factures.map((dataFacture) => (
                                            <TableRow 
                                            key={dataFacture.date}
                                            style={{ backgroundColor: getBackgroundColor(refType) }}>
                                                <TableCell component="th" scope="row">
                                                    {convertTimestampToHumandeDate(dataFacture.date)}
                                                    {dataFacture.detail ? <br/> : null}
                                                    {dataFacture.detail ? <Typography variant="caption" style={{color: "#0876ce"}}>Détail : {dataFacture.detail}</Typography> : null}
                                                </TableCell>
                                                <TableCell component="th" scope="row"><span>[{refType}]</span></TableCell>
                                                <TableCell align="right"><b>{dataFacture.after}</b> / {dataType.volumeInitial} {getUnit(refType, true)}</TableCell>
                                            </TableRow>
                                        ))
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>


            {/* Dialog Add Transaction*/}
            <Dialog 
                open={dialogSortie}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{currentType || "-"}</DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={2} justify="space-between">
                            <Grid item xs={12} md={6}>
                                <TextField 
                                fullWidth 
                                type="number" 
                                label="Quantité restante"
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">{currentType && getUnit(currentType)}</InputAdornment>,
                                    inputProps: { min: "0", step: ".1" , max: currentType && stock[currentType].stock },
                                }}
                                value={currentQuantite}
                                onChange={(e) => setCurrentQuantite(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    label="Date"
                                    type="date"
                                    fullWidth
                                    // defaultValue="2017-05-24"
                                    // className={classes.textField}
                                    value={moment.unix(currentDate).format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        setCurrentDate(convertDateToTimestamp(e.target.value))
                                        console.log(convertDateToTimestamp(e.target.value))
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth type="text" label="Détail (optionnel)" value={currentNumFacture} onChange={(e) => setCurrentNumFacture(e.target.value)}/>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button 
                    onClick={() => {
                            // setDialogSortie(false)
                            // resetCurrent()
                            window.history.back();
                        }
                    }
                    color="primary">
                        Annuler
                    </Button>
                    <Button 
                    onClick={() => {
                        // setDialogSortie(false)
                        // window.history.back();
                        window.history.back();
                        addSortie()
                    }} 
                    color="primary">
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>

        </div> 
	);
}
	
export default StockManager