import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import { navigate } from "@reach/router"
import {getVolumePileWithCotes} from "../../utils/woodtools"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';

import FormPiles from '../FormPilesNew'
import { dispatchNotification } from "../../utils/notification"


const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    },
    title: {
        marginBottom: '15px',
    },
}));

function EditChantierPiles(params) {
    const classes = useStyles();

    const [proprietaire, setProprietaire] = React.useState("");
    const [numeroChantier, setNumeroChantier] = React.useState("");
    const [date, setDate] = React.useState("");
    const [factures, setFactures] = React.useState({});
    const [soldOut, setSoldOut] = React.useState("");
    const [stocksDoc, setStocksDoc] = React.useState({});

    const [pilesDoc, setPilesDoc] = React.useState({});
    const [listeBois, setListeBois] = React.useState([]);

    const [idDocDevis, setIdDocDevis] = React.useState("");
    const [docChantierRecieved, setDocChantierRecieved] = React.useState(false);
    const [docPilesRecieved, setDocPilesRecieved] = React.useState(false);

    useEffect(()=> {
        console.log("hook setup")
        fire.firestore().collection("chantiers").doc(params.idChantier).get()
        .then((doc) => {
            if (doc.exists) {
                let data = doc.data()
                setNumeroChantier(data.numeroChantier)
                setDate(data.date)
                setProprietaire(data.proprietaire)
                setSoldOut(data.soldOut)
    
                setIdDocDevis(data.idDocDevis)
                setDocChantierRecieved(true)
            } else {
                navigate(-1)
            }
        })

        fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").get()
        .then((docs) => {
            setStocksDoc(docs)
            let factures = {}
            docs.forEach((doc) => {
                let data = doc.data()
                factures[data.type] = {
                    ...data.factures
                }
            })
            setFactures(factures)
        })

        fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").get()
        .then((docs) => {
            setPilesTest(docs)
            setDocPilesRecieved(true)
            // setPilesDoc(docs)
            // let piles = []
            // docs.forEach((doc) => {
            //     piles.push({
            //         ...doc.data()
            //     })
            // })
            // console.log(piles)
            
            // let newListeBois = [...listeBois]
            // //sort by categorie
            // piles.map((pile) => {
            //     let found = false
            //     newListeBois.map((categorie, i) => {
            //         if(categorie.type === pile.type){
            //             found = true
            //             //add pile
            //             newListeBois[i].piles.push(pile)
            //         }
            //     })
            //     if(found === false) {
            //         //new type de bois
            //         newListeBois.push({
            //             type: pile.type,
            //             cahierDesCharges: pile.cahierDesCharges,
            //             prixUnitaireHT: pile.prixUnitaireHT,
            //             isPrixGlobal: pile.isPrixGlobal,
            //             piles : [
            //                 {...pile}
            //             ]
            //         })
            //     }
            // })
            // setListeBois(newListeBois)
        });

        return () => {
        }
    }, []);
    
    const setPilesTest = (docs) => {
        setPilesDoc(docs)
        let piles = []
        docs.forEach((doc) => {
            piles.push({
                ...doc.data()
            })
        })
        console.log(piles)
        
        //sort by categorie
        let oldListeBois = [] //list recup from firebase
        piles.map((pile) => {
            let found = false

            let newPile = {}
            if(pile.longeur !== undefined) {
                newPile = {
                    longeur: pile.longeur,
                    largeur: pile.largeur,
                    hauteur: pile.hauteur,
                    numeroPile: pile.numeroPile,
                    commentaire: pile.commentaire
                }
            } else {
                newPile = {
                    volume: pile.volume,
                    numeroPile: pile.numeroPile,
                    commentaire: pile.commentaire
                }
            }

            oldListeBois.map((categorie, i) => {
                if(categorie.type === pile.type){
                    found = true
                    //add pile
                    oldListeBois[i].piles.push(newPile)
                }
            })
            if(found === false) {
                //new type de bois
                oldListeBois.push({
                    type: pile.type,
                    cahierDesCharges: pile.cahierDesCharges,
                    prixUnitaireHT: pile.prixUnitaireHT,
                    isPrixGlobal: pile.isPrixGlobal,
                    piles : [
                        {...newPile}
                    ]
                })
            }
        })

        let newListeBois = [...listeBois] //liste actuelle
        oldListeBois.map((categorie) => {
            newListeBois.map((newListe,i) => {
                if(categorie.type === newListe.type) {
                    console.log("FOUND ! replacing")
                    newListeBois.splice(i, 1)
                }
            })
            newListeBois.push({...categorie})
        })
        setListeBois(newListeBois)
    }

    useEffect(() => {
        console.log("hook DEVIS")
        if(docChantierRecieved && docPilesRecieved)
            getDevis()
    }, [docChantierRecieved, docPilesRecieved])

    const getDevis = () => {
        console.log("getDevis")
        if(idDocDevis !== undefined){
            fire.firestore().collection("devis").doc(idDocDevis).get()
            .then((doc) => {
                if (doc.exists) {
                    let dataDevis = doc.data()
                    let listeBoisDevis = []
                    dataDevis.produits.map((produit) => {
                        listeBoisDevis.push({
                            ...produit,
                            piles: []
                        })
                    })

                    let newListeBois = [...listeBois]
                    console.log([...newListeBois])
                    listeBoisDevis.map((boisD) => {
                        let found = false
                        newListeBois.map((bois) => {
                            if(boisD.type === bois.type)
                                found = true
                        })
                        if(!found) {
                            newListeBois.push({...boisD})
                        }
                    })
                    setListeBois(newListeBois)
                }
            })
        }
    }

    const modifyPiles = () => {
        let globalVolume = 0
        console.log("modifying piles");
        //delete all stock
        console.log("deleting all stock")
        stocksDoc.forEach((doc) => {
            fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").doc(doc.id).delete();
        })
        // delete all existing piles
        console.log("deleting all existing piles")
        pilesDoc.forEach((doc) => {
            fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").doc(doc.id).delete();
        })
        let oldStock = {}
        stocksDoc.forEach((doc) => {
            let data = doc.data()
            oldStock[data.type] = data
        })
        console.log("oldstock", oldStock)

        listeBois && listeBois.map((typeBois) => {
            console.log("typeBois:", typeBois)
            let volumeGlobal = 0
            typeBois.piles.map((pile)=> {
                // let volume = getVolumePileWithCotes(pile.longeur, pile.largeur, pile.hauteur, typeBois.type)
                let volume = ((pile.volume !== undefined) ? Number(pile.volume) : getVolumePileWithCotes(pile.longeur, pile.largeur, pile.hauteur, typeBois.type))
                volumeGlobal += volume
                let newPile = {
                    numeroChantier: numeroChantier, 
                    type: typeBois.type,
                    volume: Math.round((volume + Number.EPSILON) * 100) / 100,
                    soldOut: 0,
                    date: date,
                    cahierDesCharges: typeBois.cahierDesCharges,
                    prixUnitaireHT: typeBois.prixUnitaireHT,
                    isPrixGlobal: typeBois.isPrixGlobal,
                    commentaire: pile.commentaire,
                    numeroPile: pile.numeroPile
                }
                if(pile.longeur !== undefined) {
                    newPile.longeur = pile.longeur
                    newPile.largeur = pile.largeur
                    newPile.hauteur = pile.hauteur
                }
                //add piles
                console.log("new pile :",newPile)
                fire.firestore().collection("chantiers").doc(params.idChantier).collection("piles").add(newPile)
                .then(()=>{
                    console.log("added subcollection pile");
                })
                .catch((e) => {
                    console.log("failed to add pile :" + e)
                });
            });

            //add stock
            let newStock = {
                type: typeBois.type,
                cahierDesCharges: typeBois.cahierDesCharges,
                prixUnitaireHT: typeBois.prixUnitaireHT,
                isPrixGlobal: typeBois.isPrixGlobal,
                factures: factures[typeBois.type] || {},
                numeroChantier: numeroChantier,
                proprietaire: proprietaire,
                date: date,
                stock: 0,
                volumeInitial: 0
            }
            console.log(oldStock[typeBois.type])
            if(oldStock[typeBois.type]) {
                //bois existant avant la modification
                newStock.stock = oldStock[typeBois.type].stock
                // newStock.volumeInitial = oldStock[typeBois.type].volumeInitial
                
                newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100

                if( oldStock[typeBois.type].volumeInitial === oldStock[typeBois.type].stock ) {
                // if( Object.keys(oldStock[typeBois.type].factures).length === 0) {
                    // stock pas entamé, on met stock = volume initial
                    console.log(typeBois.type, "stock pas entamé")
                    newStock.stock = newStock.volumeInitial
                    // newStock.stock = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
                    // newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
                } 
                //else {
                    //stock entamé, on calcul : nouveau volume initial - volume utilisé
                    // console.log(typeBois.type, "stock entamé")
                    // newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
                    // let newStockVolume = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
                    // console.log(newStockVolume)
                    // Object.entries(oldStock[typeBois.type].factures).map(([factureId, factureData]) => {
                    //     newStockVolume -= Number(factureData.before) - Number(factureData.after)
                    // })
                    // console.log(newStockVolume)
                    // newStock.stock = newStockVolume
                // }
            } else {
                //nouveau bois
                console.log(typeBois.type, "nouveau bois")
                newStock.stock = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
                newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
            }

            // if(oldStock[typeBois.type] && (oldStock[typeBois.type].volumeInitial === oldStock[typeBois.type].stock)) {
            //     console.log("modif init AND stock")
            //     newStock.stock = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
            //     newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
            // } else {
            //     console.log("modif init")
            //     newStock.stock = oldStock[typeBois.type].stock || Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
            //     newStock.volumeInitial = Math.round((volumeGlobal + Number.EPSILON) * 100) / 100
            // }
            globalVolume += newStock.stock;
            (newStock.volumeInitial !== 0) && fire.firestore().collection("chantiers").doc(params.idChantier).collection("stock").doc(typeBois.type).set(newStock)
            .then(()=>{
                console.log("added subcollection stock");
            })
            .catch((e) => {
                console.log("failed to add stock :" + e)
            });
        })
        verifChantierVide(globalVolume)
    }

    const verifChantierVide = (stock) => {
        // console.log(stock)
        let stockTotal = stock
        // Object.entries(stock).map(([refWood, dataStock]) => {
        //     stockTotal += Number(dataStock.stock)
        // })
        console.log(stockTotal)
        if(stockTotal === 0) {
            //set SOLDOUT
            fire.firestore().collection("chantiers").doc(params.idChantier).update({
                soldOut: true
            })
            .then(() => {
                console.log("SOLD OUT !")
                dispatchNotification("Ce chantier est vide et ne contient plus de bois !")
                return true
            })
            .catch((error) => {
                console.error("Error updating soldOut: ", error);
            });
        } else if (soldOut && stockTotal !== 0) {
            //unset SOLDOUT
            fire.firestore().collection("chantiers").doc(params.idChantier).update({
                soldOut: false
            })
            .then(() => {
                console.log("NOT SOLD OUT ANYMORE !")
                return false
            })
            .catch((error) => {
                console.error("Error updating soldOut: ", error);
            });
        }
    }

	return (
		<Container maxWidth="lg" className={classes.root}>
            <form onSubmit={(e) => {
                    e.preventDefault();
                    modifyPiles();
                    navigate(-1);
                    dispatchNotification("Le chantier a été modifié !")
                }}>
                <Grid container spacing={2}>

                    {/* BOIS */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>Réception chantier - Chantier {numeroChantier}</Typography>

                            <FormPiles listeBois={listeBois} getListeBois={(e) => setListeBois(e)}/>

                        </Paper>
                    </Grid>


                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <Button color="primary" type="submit" startIcon={<DoneIcon />} variant="contained">Valider les modifications</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </form>
		</Container>
	);
}
	
export default EditChantierPiles