import React from "react"
import PropTypes from "prop-types"
import { navigate, Redirect } from "@reach/router"
import { isLoggedIn } from "../utils/auth"

import ScrollToTop from '../ScrollToTop'
import { useEffect } from "react"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	useEffect(() => {
		if (!isLoggedIn() && location.pathname !== `/login`) {
			// return <Redirect from="/" to="/login" />
			navigate(`/login`, { replace: true })
		}
	}, [])

	// if (!isLoggedIn() && location.pathname !== `/login`) {
	// 	// If we’re not logged in, redirect to the home page.
	// 	return <Redirect from="/" to="/login" />
	// } else {
	// 	return [<ScrollToTop key="scrollTop"/>,<Component {...rest} key="comp"/>]
	// }
	// return <Component {...rest} />

	return [<ScrollToTop key="scrollTop"/>,<Component {...rest} key="comp"/>]
}

// PrivateRoute.propTypes = {
//   component: PropTypes.any.isRequired,
// }

export default PrivateRoute
