import React, { useState, useEffect } from 'react';
import axios from "axios"
import fire from '../utils/fire';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import NotesIcon from '@material-ui/icons/Notes';
import HelpIcon from '@material-ui/icons/Help';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsIcon from '@material-ui/icons/Settings';
import CircularProgress from '@material-ui/core/CircularProgress';
import { functions } from 'firebase';

function FillCoordsButton(props) {
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState("");

    const callFunction = () => {
        setLoading(true)
        axios.get("https://europe-west2-my-foret-app.cloudfunctions.net/fillCoordsChantierOnDemand?idDoc="+props.idDocChantier).then((response) => {
            console.log(response)
            setStatus(response.data.result)
            setLoading(false)
        }).catch(e => {
            console.log(e)
            setStatus("error")
            setLoading(false)
        })

        // functions.call('helloWorld').then((res) => {
        //     console.log(res)
        // })

        // let tmp = functions.httpsCallable('helloWorld')
        // const fun = fire.functions("europe-west2").httpsCallable('fillCoordsChantierOnDemand');
        // fun({"idDoc": "e8HmKpsRuNhrlKclECRo"}).then((res) => {
        //     console.log(res)
        //     setStatus(res)
        //     setLoading(false)
        // }).catch((e) => {
        //     setLoading(false)
        //     console.log("error")
        //     console.log(e)
        // })
        // let tmp = functions.Functions.call('helloWorld')
        // axios.get("https://script.google.com/macros/s/AKfycbzlsg49yhbJcvEeKD7Un2puSyW7JcVaerdjgnJYzZWMPqo6hCqGj6kw9qVbg-FIRuhh/exec?url=www.google.com")
    }

    const getStatus = () => {
        switch(status) {
            // case "idDoc undefined" || "doc don't exist":
            case "":
                return null
            case "done":
                return <Typography variant="caption" color="primary"><i>Coordonnées trouvées !</i></Typography>
            case "fail to find coordinates" || "no link in lienMaps":
                return <Typography variant="caption" color="secondary"><i>Coordonnées introuvables, essayez de modifier le lien</i></Typography>
            // case "APP SCRIPT PROBLEM":
            //     return <Typography variant="caption" color="secondary"><i>Coordonnées introuvables, verification impossible.</i></Typography>
            default:
                return <Typography variant="caption" color="secondary"><i>Coordonnées introuvables, erreur critique.</i></Typography>
        }
    }

	return (
        // <Paper variant="outlined" style={{padding: "11px"}}>
            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    <Typography variant="h6">Conditions particulières</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField 
                    fullWidth 
                    label="Paiement" 
                    multiline 
                    value={props.paiement} 
                    onChange={(e) => props.getPaiement(e.target.value)}
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title="Texte paiement par défaut">
                                <IconButton aria-label="Par default" onClick={() => setOpenDialog(true)}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Texte paiement par défaut">
                                <IconButton color="primary" aria-label="Par default" 
                                onClick={setDefaultPaiement}
                                >
                                    <NotesIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Condition d'achat" multiline value={props.condition} 
                    onChange={(e) => props.getCondition(e.target.value)}
                    />
                </Grid> */}
                <Grid item xs={12} container alignItems="center">
                    <Button
                        startIcon={<SettingsIcon />}
                        variant="outlined"
                        disabled={loading}
                        size="small"
                        // variant="contained"
                        onClick={() => {
                            setLoading(true)
                            callFunction()
                        }}
                    >
                        {
                            loading ? 
                            <CircularProgress size={22} /> :
                            "Verifier le lien"
                        }
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {/* <Typography variant="caption" color="secondary"><i>Les chantiers fermés n'apparaîssent pas sur My-Forêt Map</i></Typography> */}
                    {getStatus()}
                </Grid>
            </Grid>
        // </Paper>
    );
}
	
export default FillCoordsButton