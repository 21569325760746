import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DevisListItem from "./DevisListItem"

/**
 * @param {object} devis liste brute de firebase
 */
function ListeDeDevis(props) {
    
    // useEffect(() => {
    //     console.warn("devis", props.devis)
    // }, [props])
	
	return (
        <Grid container spacing={2} justify="space-around" alignItems="stretch">
            {props.devis.map((devis, index) => {
                let dataDevis = devis.data()
                return(
                    <DevisListItem
                    devis={dataDevis}
                    idDoc={devis.id}
                    key={devis.id}/>
                )
            })}
        </Grid>
	);
}

export default ListeDeDevis;
		