import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Hidden from '@material-ui/core/Hidden';
import { Divider } from '@material-ui/core';

/**
 * @param paymentDate
 * @callback setPaymentDate
 * 
 * @param paid
 * @callback setPaid
 * 
 * @param paymentMessage
 * @callback setPaymentMessage
 * 
 * @param paymentMethod
 * @callback setPaymentMethod
 */
function PaymentForm( props ) {
	return (
		<Grid container spacing={2}>

            <Grid item xs={10} sm>
                <TextField
                fullWidth
                label="Date de Paiement"
                type="date"
                value={props.paymentDate}
                onChange={(e) => props.setPaymentDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}/>
            </Grid>
            <Grid item xs={2} sm={"auto"}>
                <IconButton aria-label="vider" onClick={() => props.setPaymentDate("")}>
                    <CloseIcon />
                </IconButton>
            </Grid>

            <Hidden xsDown>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm={"auto"} style={{
                    "display": "flex",
                    "justifyContent": "center",
                    "padding": "0 25px"
                }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.paid}
                                onChange={() => props.setPaid(!props.paid)}
                                name="paid"
                                color="primary"
                            />
                        }
                        label="Payé"
                    />
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Grid item xs={12} sm={"auto"} style={{"display": "flex"}}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.paid}
                                onChange={() => props.setPaid(!props.paid)}
                                name="paid"
                                color="primary"
                            />
                        }
                        label="Payé"
                    />
                </Grid>
            </Hidden>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    label="Commentaire"
                    value={props.paymentMessage}
                    onChange={(e) => props.setPaymentMessage(e.target.value)}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Mode de paiement</FormLabel>
                    <RadioGroup 
                    row
                    defaultValue="virement"
                    aria-label="payment"
                    name="payment" 
                    value={props.paymentMethod}
                    onChange={(e) => props.setPaymentMethod(e.target.value)}
                    >
                        <FormControlLabel value="transfer" control={<Radio color="primary" />} label="Virement" />
                        <FormControlLabel value="check" control={<Radio color="primary" />} label="Chèque" />
                        <FormControlLabel value="other" control={<Radio color="primary" />} label="Autre" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
	);
}
	
export default PaymentForm