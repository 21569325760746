import React from 'react';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Hidden from '@material-ui/core/Hidden';
import { Divider } from '@material-ui/core';

/**
 * @param purchaseType
 * @callback setPurchaseType
 * 
 * @param responsable
 * @callback setResponsable
 * 
 * @param year YY
 * @callback setYear
 * 
 * @param numeroChantier
 * @callback setNumeroChantier
 */
function NumChantierFrom( props ) {
    const buildYears = () => {
		let years = []
		let year = Number(moment().format("YYYY"))
		do {
            const id = year.toString().substring(2, 4);
			years.push(
				<option value={id} default key={id}>{year}</option>
			)
			year = year - 1
		} while(year >= 2020)
		return years
	}

	return (
		<Grid container spacing={1}>
            <Grid item xs="auto">
                <Select
                fullWidth
                variant="outlined"
                native
                value={props.purchaseType}
                onChange={(e) => {
                    props.setPurchaseType(e.target.value)
                }}
                >
                    <option value="UP" default>UP</option>
                    <option value="BR">BR</option>
                    <option value="EB">EB</option>
                </Select>
            </Grid>
            <Grid item xs sm="auto">
                <Select
                fullWidth
                variant="outlined"
                native
                value={props.responsable}
                onChange={(e) => {
                    props.setResponsable(e.target.value)
                }}
                >
                    <option value="A" default>Anthony</option>
                    <option value="D">Dylan</option>
                    <option value="G">Gabin</option>
                </Select>
            </Grid>
            <Grid item xs="auto">
                <Select
                fullWidth
                variant="outlined"
                native
                value={props.year}
                onChange={(e) => {
                    props.setYear(e.target.value)
                }}
                >
                    {buildYears()}
                </Select>
            </Grid>
            <Grid item xs={12} sm>
                <TextField
                fullWidth
                variant="outlined"
                required
                label="Numéro chantier"
                type="number"
                InputProps={{
                    startAdornment: <InputAdornment position="start">{props.responsable}-{props.year}-</InputAdornment>,
                    }}
                value={props.numeroChantier}
                onChange={(e) => props.setNumeroChantier(e.target.value)}/>
            </Grid>
        </Grid>
	);
}
	
export default NumChantierFrom