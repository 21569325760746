import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NoteIcon from '@material-ui/icons/Note';

import { convertTimestampToHumandeDate } from "../utils/woodtools"

/**
 * @param paymentDate
 * @param paid
 * @param paymentMessage
 * @param paymentMethod
 * 
 * @output togglePaid
 */
function PaymentDisplay( props ) {
    const handleTogglePaid = () => props.togglePaid()
    console.log("Payment props:", props)
	return (
		<Grid container spacing={2}>
            { props.paymentDate ? 
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2">Date du paiement</Typography>
                    <Typography variant="body1">
                        {convertTimestampToHumandeDate(props.paymentDate) || "-"} -
                        {props.paid ? <span style={{color:"#57b906"}}> Payé <AttachMoneyIcon style={{margin: "0 0 -5px -3px"}}/></span> :
                        <b><span style={{color:"#f50057"}}> Non payé <MoneyOffIcon style={{margin: "0 0 -5px -3px"}}/></span></b>}
                    </Typography>
                    <Typography variant="caption" color="secondary" style={{color: "#0084f5", cursor: "pointer", "textDecoration": "underline"}} onClick={() => handleTogglePaid()}>
                        Marquer comme {props.paid ? "\"Non payé\"" : "\"Payé\""}
                    </Typography>
                </Grid> : 
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2">Paiement client</Typography>
                    <Typography variant="body1">
                        {props.paid ? 
                            <span style={{color:"#57b906"}}>Payé <AttachMoneyIcon style={{margin: "0 0 -5px -3px"}}/></span> :
                            <b><span style={{color:"#f50057"}}>Non payé <MoneyOffIcon style={{margin: "0 0 -5px -3px"}}/></span></b>
                        }
                    </Typography>
                    <Typography variant="caption" color="secondary" style={{color: "#0084f5", cursor: "pointer", "textDecoration": "underline"}} onClick={() => handleTogglePaid()}>
                        Marquer comme {props.paid ? "\"Non payé\"" : "\"Payé\""}
                    </Typography>
                </Grid>
            }

            {props.paymentMethod && 
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2">Paiement</Typography>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {props.paymentMethod === "transfer" && <AccountBalanceIcon fontSize="small" style={{"marginTop": "3px"}}/>}
                            {props.paymentMethod === "check" && <NoteIcon fontSize="small" style={{"marginTop": "3px"}}/>}
                            {props.paymentMethod === "other" && <AttachMoneyIcon fontSize="small" style={{"marginTop": "3px"}}/>}
                        </Grid> 
                        <Grid item>
                            {props.paymentMethod === "transfer" && <Typography variant="body1">Virement</Typography>}
                            {props.paymentMethod === "check" && <Typography variant="body1">Chèque</Typography>}
                            {props.paymentMethod === "other" && <Typography variant="body1"><i>non renseigné</i></Typography>}
                        </Grid>
                    </Grid>
                    {false ? <Typography variant="body1">{props.codePefc || "-"}</Typography> : ""}
                </Grid>
            }

            {props.paymentMessage && 
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2">Commentaire</Typography>
                    <Typography variant="body1">{props.paymentMessage}</Typography>
                </Grid>
            }
        </Grid>
	);
}
	
export default PaymentDisplay