import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import { navigate } from "@reach/router"
import {getCoupeurs,
    getDebardeurs,
    getVolumePileWithCotes,
    convertDateToTimestamp,
    convertTimestampToDate} from "../../utils/woodtools"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import DoneIcon from '@material-ui/icons/Done';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Geolocalisation from '../Geolocalisation';
import FormPiles from '../FormPilesNew';
import PaymentForm from '../PaymentForm'
import ConditionsParticulieres from "../ConditionsParticulieres"
import { dispatchNotification } from "../../utils/notification"
import NumChantierForm from '../NumChantierForm';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingRight: '8px',
        },
    },
    paper: {
        padding: '20px',
        [theme.breakpoints.down('xs')]: {
            padding: '12px 15px'
        },
    },
    paperTypeBois: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 9px'
        },
    },
    buttonPile: {
        padding: '5px',
        width: "100%"
    },
    commentairePile: {
        color: "#0876ce",
        marginTop: "3px"
    },
    formControl: {
        width: "100%",
    },
    title: {
        marginBottom: '15px',
    },
    cardPile: {
        padding: '5px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '16px'
        },
    },
    cardTotalPile: {
        padding: '5px',
    }
}));

function ChantierFromDevis(params) {
    const classes = useStyles();

    const [devis, setDevis] = React.useState([]);
    const [idDocDevis, setIdDocDevis] = React.useState(false);

    const [purchaseType, setPurchaseType] = useState("UP");
    const [responsable, setResponsable] = useState("A");
    const [year, setYear] = useState((new Date().getFullYear()).toString().substring(2, 4));
    const [numeroChantier, setNumeroChantier] = useState("");

    const [proprietaire, setProprietaire] = React.useState("");
    const [localisation, setLocalisation] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [date, setDate] = React.useState("");
    const [pefc, setPefc] = React.useState(false);
    const [codePefc, setCodePefc] = React.useState("");

    const [paymentDate, setPaymentDate] = React.useState("");
    const [paid, setPaid] = React.useState(false);
    const [paymentMessage, setPaymentMessage] = React.useState("");
    const [paymentMethod, setPaymentMethod] = React.useState("transfer");

    const [selectCoupeur, setSelectCoupeur] = React.useState("");
    const [coupeur, setCoupeur] = React.useState("");
    const [selectDebardeur, setSelectDebardeur] = React.useState("");
    const [debardeur, setDebardeur] = React.useState("");
    const [autre, setAutre] = React.useState("");

    const [latitude, setLatitude] = React.useState("");
    const [longitude, setLongitude] = React.useState("");
    const [lienMaps, setLienMaps] = React.useState("");

    const [paiement, setPaiement] = React.useState("");
    const [servitude, setServitude] = React.useState("");
    const [delais, setDelais] = React.useState("");
    const [observations, setObservations] = React.useState("");
    const [condition, setCondition] = React.useState("");

    const [listeBois, setListeBois] = React.useState([]);

    useEffect(()=> {
        let unsubscribeGlobalParams = fire.firestore().collection("globalParams").doc("tracking").onSnapshot((doc) => {
            if (doc.exists) {
                let trackingDoc = doc.data()
                setNumeroChantier(trackingDoc.numChantier)
            } else {
                console.log("Error !")
            }
        });

        fire.firestore().collection("devis").doc(params.idDevis).get()
        .then((doc) => {
            if (doc.exists) {
                let dataDevis = doc.data()
                console.log("Doc id from cache : " + doc.metadata.fromCache);
                console.log("Document data:", doc.data());
                switch(String(dataDevis.numeroDevis).charAt(0)) {
                    case "A" :
                        setResponsable("A")
                        break;
                    case "D" :
                        setResponsable("D")
                        break;
                    case "G" :
                        setResponsable("G")
                        break;
                    default :
                        setResponsable("A")
                }
                // setResponsable(responsableDevis)
                setDevis(dataDevis)
                setIdDocDevis(doc.id)
                setProprietaire(dataDevis.proprietaire)
                setLocalisation(dataDevis.localisation)
                setEmail(dataDevis.email)
                setTelephone(dataDevis.telephone)
                setPefc(dataDevis.pefc)
                setCodePefc(dataDevis.codePefc)
                setPaymentDate(convertTimestampToDate(dataDevis.paymentDate))
                setPaiement(dataDevis.paiement || "")
                setServitude(dataDevis.servitude)
                setDelais(dataDevis.delais)
                setObservations(dataDevis.observations)
                setCondition(dataDevis.condition)
                setLatitude(dataDevis.latitude || "")
                setLongitude(dataDevis.longitude || "")
                setLienMaps(dataDevis.lienMaps || "")

                let newListeBois = []
                dataDevis.produits.map((produit) => {
                    newListeBois.push({
                        ...produit,
                        piles: []
                    })
                })
                console.log(newListeBois)
                setListeBois(newListeBois)
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                navigate('/liste-chantiers/now')
            }
        });

        return () => {
            unsubscribeGlobalParams()
        }
    }, []);

    const addChantier = () => {
        let newNumeroChantier = responsable+"-"+year+"-"+numeroChantier
        let timestamp = convertDateToTimestamp(date)
        fire.firestore().collection("chantiers").add({
            purchaseType: purchaseType,
            numeroChantier: newNumeroChantier,
            proprietaire: proprietaire,
            localisation: localisation,
            date: timestamp,
            email: email,
            telephone: telephone,
            pefc: pefc,
            codePefc: codePefc,
            paymentDate: convertDateToTimestamp(paymentDate),
            paid: paid,
            paymentMessage: paymentMessage,
            paymentMethod: paymentMethod,
            coupeur: (selectCoupeur === "autre" ? coupeur : selectCoupeur),
            debardeur: (selectDebardeur === "autre" ? debardeur : selectDebardeur),
            autre: autre,
            latitude: latitude,
            longitude: longitude,
            lienMaps: lienMaps,
            paiement: paiement,
            servitude: servitude,
            delais: delais,
            observations: observations,
            condition: condition,
            soldOut: false,
            numeroDevis: devis.numeroDevis,
            idDocDevis: idDocDevis
        })
        .then((docRef) => {
            fire.firestore().collection("devis").doc(idDocDevis).update({
                idDocChantier: docRef.id
            })

            console.log("added document chantier");
            listeBois && listeBois.map((typeBois) => {
                let volumeGlobal = 0
                typeBois.piles.map((pile)=> {
                    // let volume = getVolumePileWithCotes(pile.longeur, pile.largeur, pile.hauteur, typeBois.type)
                    let volume = ((pile.volume !== undefined) ? Number(pile.volume) : getVolumePileWithCotes(pile.longeur, pile.largeur, pile.hauteur, typeBois.type))
                    volumeGlobal += volume
                    let newPile = {
                        numeroChantier: newNumeroChantier,
                        type: typeBois.type,
                        volume: Math.round((volume + Number.EPSILON) * 100) / 100,
                        // volumeAvailable: Math.round((volume + Number.EPSILON) * 100) / 100,
                        soldOut: 0,
                        date: timestamp,
                        cahierDesCharges: typeBois.cahierDesCharges,
                        prixUnitaireHT: typeBois.prixUnitaireHT,
                        isPrixGlobal: typeBois.isPrixGlobal,
                        commentaire: pile.commentaire,
                        numeroPile: pile.numeroPile
                    }
                    if(pile.longeur !== undefined) {
                        newPile.longeur = pile.longeur
                        newPile.largeur = pile.largeur
                        newPile.hauteur = pile.hauteur
                    }
                    fire.firestore().collection("chantiers").doc(docRef.id).collection("piles").add(newPile)
                    .then(()=>{
                        console.log("added subcollection pile");
                    })
                    .catch((e) => {
                        console.log("failed to add pile :" + e)
                    });
                });

                fire.firestore().collection("chantiers").doc(docRef.id).collection("stock").doc(typeBois.type).set({
                    stock: Math.round((volumeGlobal + Number.EPSILON) * 100) / 100,
                    type: typeBois.type,
                    cahierDesCharges: typeBois.cahierDesCharges,
                    prixUnitaireHT: typeBois.prixUnitaireHT,
                    isPrixGlobal: typeBois.isPrixGlobal,
                    volumeInitial: Math.round((volumeGlobal + Number.EPSILON) * 100) / 100,
                    factures: {},
                    numeroChantier: newNumeroChantier,
                    proprietaire: proprietaire,
                    date: timestamp,
                })
                .then(()=>{
                    console.log("added subcollection stock");
                })
                .catch((e) => {
                    console.log("failed to add stock :" + e)
                });
                
            })

            fire.firestore().collection("globalParams").doc("tracking").update({
                numChantier: Number(numeroChantier)+1
            })
            // if (numeroChantier === globalParams.numChantier) {
            //     fire.firestore().collection("globalParams").doc("tracking").update({
            //         numChantier: globalParams.numChantier+1
            //     })
            // }
            // if (numeroChantier > globalParams.numChantier) {
            //     fire.firestore().collection("globalParams").doc("tracking").update({
            //         numChantier: numeroChantier
            //     })
            // }

            // navigate('/show-chantier/'+docRef.id)
            //TODO : le retour à la liste est peut être plus compréhensible, on voit le chantier apparaitre
        })
        .catch((e) => {
            console.log("failed to add chantier :" + e)
        })
    }

    const togglePefc = (checked) => {
        setPefc(checked);
        if(!checked){
            setCodePefc("");
        }
    }

	return (
		<Container maxWidth="lg" className={classes.root}>
            <form onSubmit={(e) => {
                    e.preventDefault();
                    addChantier();
                    navigate('/liste-chantiers/now');
                    dispatchNotification("Le chantier a été enregistré !")
                }}>
                <Grid container spacing={2}>
                    {/* INFOS */}
                    <Grid item xs={12} lg={8}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>Coordonnées du chantier - Devis n°{devis.numeroDevis || "-"}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} spacing={2}>
                                    <NumChantierForm
                                    purchaseType={purchaseType}
                                    setPurchaseType={(type) => setPurchaseType(type)}
                                    responsable={responsable}
                                    setResponsable={(responsable) => setResponsable(responsable)}
                                    year={year}
                                    setYear={(year) => setYear(year)}
                                    numeroChantier={numeroChantier}
                                    setNumeroChantier={(num) => setNumeroChantier(num)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required label="Propriétaire" value={proprietaire} onChange={(e) => setProprietaire(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Localisation" value={localisation} onChange={(e) => setLocalisation(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth type="email" 
                                    InputProps={{
                                        inputProps: { inputMode: "email" }
                                    }}
                                    label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth type="tel"
                                    InputProps={{
                                        inputProps: { inputMode: "tel" }
                                    }}
                                    label="Téléphone" value={telephone} onChange={(e) => setTelephone(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                    required
                                    label="Date du Chantier"
                                    type="date"
                                    fullWidth
                                    // defaultValue="2017-05-24"
                                    // className={classes.textField}
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} sm={2}>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={pefc}
                                                    onChange={(e) => togglePefc(e.target.checked)}
                                                    name="pefc"
                                                    color="primary"
                                                />
                                                }
                                                label="PEFC"
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                        { pefc ? <TextField fullWidth variant="outlined" size="small" label="Code PEFC" value={codePefc} onChange={(e) => setCodePefc(e.target.value)}/> : ""}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* EQUIPE */}
                    <Grid item xs={12} lg={4}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>Équipe</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm lg={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Coupeur</InputLabel>
                                        <Select
                                            native
                                            name="Coupeur"
                                            value={selectCoupeur}
                                            onChange={(e) => setSelectCoupeur(e.target.value)}
                                            >
                                                <option value="" />
                                                {getCoupeurs().map((coupeur, index) => {
                                                    return <option key={index} value={coupeur} default>{coupeur}</option>
                                                })}
                                                <option value="autre">Autre</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {selectCoupeur === "autre" && 
                                [<Grid item xs={12} sm={8} lg={12}>
                                    <TextField fullWidth label="Nom du Coupeur" value={coupeur} onChange={(e) => setCoupeur(e.target.value)}/>
                                </Grid>,
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>]}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm lg={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Débardeur</InputLabel>
                                        <Select
                                            native
                                            name="Debardeur"
                                            value={selectDebardeur}
                                            onChange={(e) => setSelectDebardeur(e.target.value)}
                                            >
                                                <option value="" />
                                                {getDebardeurs().map((debardeur, index) => {
                                                    return <option key={index} value={debardeur} default>{debardeur}</option>
                                                })}
                                                <option value="autre">Autre</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {selectDebardeur === "autre" && 
                                [<Grid item xs={12} sm={8} lg={12}>
                                    <TextField fullWidth label="Nom du Debardeur" value={debardeur} onChange={(e) => setDebardeur(e.target.value)}/>
                                </Grid>,
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>]}

                                <Grid item xs={12}>
                                    <TextField fullWidth label="Autre" value={autre} onChange={(e) => setAutre(e.target.value)}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" gutterBottom>Paiement du client</Typography>
                            <PaymentForm
                            paymentDate={paymentDate}
                            setPaymentDate={(date) => setPaymentDate(date)}
                            paid={paid}
                            setPaid={(paid) => setPaid(paid)} 
                            paymentMessage={paymentMessage}
                            setPaymentMessage={(message) => setPaymentMessage(message)}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={(method) => setPaymentMethod(method)} />
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Geolocalisation 
                            getLatitude={(e) => setLatitude(e)}
                            latitude={latitude}
                            getLongitude={(e) => setLongitude(e)} 
                            longitude={longitude}
                            lienMaps={lienMaps}
                            getLienMaps={(e) => setLienMaps(e)} />
                        </Paper>
                    </Grid>

                    {/* CONTEXTE */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <ConditionsParticulieres
                            getPaiement={(e) => setPaiement(e)}
                            paiement={paiement}
                            getServitude={(e) => setServitude(e)}
                            servitude={servitude}
                            getDelais={(e) => setDelais(e)}
                            delais={delais}
                            getObservations={(e) => setObservations(e)}
                            observations={observations}
                            getCondition={(e) => setCondition(e)}
                            condition={condition} />
                        </Paper>
                    </Grid>
                    

                    {/* BOIS */}
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h6" className={classes.title}>Réception chantier</Typography>
                            
                            <FormPiles listeBois={listeBois} getListeBois={(e) => setListeBois(e)}/>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {/* <Paper className={classes.paper}> */}
                            <Grid container justify="flex-end">
                                <Button color="primary" type="submit" startIcon={<DoneIcon />} variant="contained">Valider le chantier</Button>
                            </Grid>
                        {/* </Paper> */}
                    </Grid>

                </Grid>
            </form>
            
		</Container>
	);
}
	
export default ChantierFromDevis