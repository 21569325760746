import React, { useState, useEffect } from 'react';
import fire from '../../utils/fire';
import { navigate, useLocation } from "@reach/router"
import moment from "moment"

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import ListeDeChantiers from '../ListeDeChantiers';


const useStyles = makeStyles((theme) => ({
    root: {
		marginBottom: '150px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '6px',
			paddingRight: '12px',
			marginBottom: '85px',
        },
	},
	fab: {
		position: "fixed",
		bottom: "60px",
		right: "60px",
		zIndex: "999",
		[theme.breakpoints.down('lg')]: {
			bottom: "50px",
			right: "50px",
		},
		[theme.breakpoints.down('md')]: {
			bottom: "40px",
			right: "40px",
		},
		[theme.breakpoints.down('xs')]: {
			bottom: "30px",
			right: "30px",
		},
	},
	fabSearch: {
		position: "fixed",
		bottom: "130px",
		right: "60px",
		zIndex: "999",
		[theme.breakpoints.down('lg')]: {
			bottom: "120px",
			right: "50px",
		},
		[theme.breakpoints.down('md')]: {
			bottom: "110px",
			right: "40px",
		},
		[theme.breakpoints.down('xs')]: {
			bottom: "90px",
			right: "30px",
		},
	}
}));

function ListeChantiers(params) {
	const classes = useStyles();
	const [chantiers, setChantiers] = useState([]);
	const [stocks, setStocks] = useState([]);
	const [openDialogPDF, setOpenDialogPDF] = useState(false);
	const [currentChantier, setCurrentChantier] = useState(false);

	const [openAccordion, setOpenAccordion] = useState(false);
	
	const location = useLocation();

	useEffect(() => {
		let startAt;
		let endAt;
		if(params.year === "now") {
			startAt = parseInt((new Date("1999").getTime() / 1000).toFixed(0))
			endAt = parseInt((new Date("2200").getTime() / 1000).toFixed(0))
		} else {
			let startYear = moment(params.year);
			let endYear = moment(params.year).add(1, 'years');
			// console.log("years", startYear.format("YYYY"), endYear.format("YYYY"));
			startAt = parseInt((new Date(startYear).getTime() / 1000).toFixed(0))
			endAt = parseInt((new Date(endYear).getTime() / 1000).toFixed(0))
		}
		console.log({startAt, endAt})
		

		let unsubscribe = fire.firestore().collection("chantiers").orderBy("date", "desc").startAt(endAt).endAt(startAt).onSnapshot((snapshot) => {
			let docs = [];

			snapshot.forEach((doc) => {
				docs.push(doc)
			})
			docs.sort(sortByNumChantier)

			setChantiers(docs)
		});

		let stocksUnsubscribe = fire.firestore().collectionGroup("stock").onSnapshot((snapshot) => {
			let stockList = {};
            snapshot.forEach((doc) => {
				if (stockList[doc.ref.parent.parent.id] === undefined) {
					stockList[doc.ref.parent.parent.id] = []
				}
                stockList[doc.ref.parent.parent.id].push(doc.data()) 
			})
			setStocks(stockList)
		});
		
		return () => {
			unsubscribe()
			stocksUnsubscribe()
		}
	}, [location]);

	const sortByNumChantier = (a, b) => {
		let numChantierA = a.data().numeroChantier.split('-');
		let numChantierB = b.data().numeroChantier.split('-');
		
		if(numChantierA[1] === numChantierB[1]) {
			return -(numChantierA[2] - numChantierB[2]);
		} else {
			return -(numChantierA[1] - numChantierB[1]);
		}
	}
	
	return (
		<Container maxWidth="lg" className={classes.root}>
			
			<Hidden smUp>
				<Fab color="primary" size="medium" aria-label="add" className={classes.fabSearch} onClick={() => navigate('/search-chantiers')}>
					<SearchIcon />
				</Fab>
			</Hidden>
			<Hidden xsDown>
				<Fab color="primary" aria-label="search" className={classes.fabSearch} onClick={() => navigate('/search-chantiers')}>
					<SearchIcon />
				</Fab>
			</Hidden>

			<Hidden smUp>
				<Fab color="secondary" size="medium" aria-label="add" className={classes.fab} onClick={() => navigate('/new-chantier')}>
					<AddIcon />
				</Fab>
			</Hidden>
			<Hidden xsDown>
				<Fab color="secondary" aria-label="add" className={classes.fab} onClick={() => navigate('/new-chantier')}>
					<AddIcon />
				</Fab>
			</Hidden>

			<ListeDeChantiers chantiers={chantiers} stocks={stocks} />

		</Container>
	);
}

export default ListeChantiers
		