import firebase from 'firebase'

// TEST BASE
// var config = {
//     apiKey: "AIzaSyDCnVbogrlzapzARtoarXGufb6rIifQjOs",
//     authDomain: "stock-bois-test.firebaseapp.com",
//     databaseURL: "https://stock-bois-test.firebaseio.com",
//     projectId: "stock-bois-test",
//     storageBucket: "stock-bois-test.appspot.com",
//     messagingSenderId: "472527591857",
//     appId: "1:472527591857:web:42af3b8f760441c96ac590"
// };

//NEW BASE
var config = {
    apiKey: "AIzaSyCmJ7BwPP-_hsGunl5RTHhyPlcWPg7OTWk",
    authDomain: "my-foret-app.firebaseapp.com",
    projectId: "my-foret-app",
    storageBucket: "my-foret-app.appspot.com",
    messagingSenderId: "35096605651",
    appId: "1:35096605651:web:e3c2e2b22cf63d6c21f526",
    measurementId: "G-T4DGWZTW3H"
};


var fire = firebase.initializeApp(config);
fire.firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(err => console.log(err));
    
export default fire;